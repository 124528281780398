<template>
  <div ref="bucket-list-container" class="col position-relative">
    <h3>Bucket List</h3>
    <ul v-if="pagedBucketList.length > 0" class="list-group list-group-flush">
      <li
        v-for="item in pagedBucketList"
        :key="item.id"
        class="list-group-item"
      >
        <i v-if="item.completed" class="me-2 text-success fas fa-trophy"></i
        >{{ item.text }}
      </li>
    </ul>
    <div v-else class="text-center py-4">
      <span class="text-muted"> No bucket list items for patient </span>
    </div>
    <tbl-page-seln
      v-model="pgNum"
      :total-pages="totalPages"
      @updated="refresh"
    />
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import {
  getLodashArray,
  getPage,
} from '../../../../../../services/lib/clientPaging';
import TblPageSeln from '../../../../shared/components/TblPageSeln.vue';

export default {
  components: {
    TblPageSeln,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const lifePlanApi = inject('api').lifeplanApi();
    const loading = inject('$loading');

    onMounted(() => {
      refresh();
    });

    const pagedBucketList = ref([]);
    const pgNum = ref(1);
    const pgSz = ref(10);
    const sortField = ref('text');
    const sortDirection = ref('asc');
    const totalPages = ref(0);
    const bucketListContainer = ref(null);

    const refresh = async () => {
      const loader = loading.show({
        container: bucketListContainer.value,
        isFullPage: false,
      });
      const response = await lifePlanApi.getPatientBucketList(props.patientId);
      const bucketList = response.data;
      const result = getPage(
        getLodashArray(bucketList),
        pgNum.value, // page to display
        pgSz.value,
        () => true, // filter by bucket item text
        (o) => o[sortField.value].toLowerCase(), // sorting property
        sortDirection.value // sorting direction
      );
      pagedBucketList.value = result.data;
      totalPages.value = result.totalPages;
      loader.hide();
    };

    return {
      'bucket-list-container': bucketListContainer,
      pagedBucketList,
      pgNum,
      totalPages,
      refresh,
    };
  },
};
</script>
