<template>
  <div class="small text-center d-flex justify-content-start pb-4">
    <router-link
      id="back-btn"
      :to="{
        name: 'patients-view',
        query: { tab: 'goals-actions' },
      }"
      class="btn btn-sm btn-link link-secondary"
    >
      <i class="fas fa-angle-double-left"></i>
      Back to Goals &amp; Actions</router-link
    >
  </div>
  <div ref="action-template-container" class="row position-relative">
    <div class="col-md-6">
      <div class="row g-2">
        <div class="col-12">
          <div id="action-rte-container">
            <rich-text-editor
              v-model:data="nameObj"
              container-id="action-rte-container"
              property="actionName"
              height="10vh"
              @dirty="isDirty = true"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating mb-3">
            <textarea
              id="action-description"
              v-model="description"
              class="form-control"
              placeholder="LOptional Description"
              @input="isDirty = true"
            ></textarea>
            <label for="action-description"
              >Default Description (optional)</label
            >
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-3">
            <select
              id="actionDefaultDimension"
              v-model="dimensionId"
              class="form-select"
              @input="isDirty = true"
            >
              <option :value="null">Unassigned</option>
              <option
                v-for="dimension in dimensions"
                :key="dimension.id"
                :value="dimension.id"
              >
                {{ dimension.text }}
              </option>
            </select>
            <label for="actionDefaultDimension"
              >Default Dimension (optional)</label
            >
          </div>
        </div>
      </div>
      <div class="col text-end">
        <button
          :id="`create-action-template-btn`"
          :disabled="!nameObj.actionName || nameObj.actionName == '<p><br></p>'"
          type="button"
          class="btn btn-primary"
          @click="createTemplate"
        >
          Save Action
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject, onBeforeMount, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import RichTextEditor from '../../../shared/components/RichTextEditor.vue';

export default {
  components: {
    RichTextEditor,
  },
  setup() {
    const generalDataApi = inject('api').generalDataApi();
    const profApi = inject('api').profApi();
    const loading = inject('$loading');

    const actionTemplateContainer = ref(null);
    const dimensions = ref([]);
    onMounted(async () => {
      const loader = loading.show({
        container: actionTemplateContainer.value,
        isFullPage: false,
      });
      const response = await generalDataApi.dimensions();
      dimensions.value = response.data;
      loader.hide();
    });

    const nameObj = ref({ actionName: '<p><br></p>' });
    const description = ref('');
    const dimensionId = ref(null);
    const router = useRouter();
    const createTemplate = async () => {
      if (
        !nameObj.value.actionName ||
        nameObj.value.actionName == '<p><br></p>'
      )
        return;
      const loader = loading.show({
        container: actionTemplateContainer.value,
        isFullPage: false,
      });
      await profApi.createActionTemplate(
        nameObj.value.actionName,
        description.value,
        dimensionId.value
      );
      loader.hide();
      isDirty.value = false;
      router.replace({
        name: 'patients-view',
        query: {
          tab: 'goals-actions',
        },
      });
    };

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      'action-template-container': actionTemplateContainer,
      dimensions,
      nameObj,
      description,
      dimensionId,
      createTemplate,
      isDirty,
    };
  },
};
</script>
