<template>
  <button
    id="shared-entry-expansion-btn"
    type="button"
    class="list-group-item list-group-item-action border position-relative"
    @click="toggleExpanded()"
  >
    <div v-if="!isExpanded" class="d-flex justify-content-between">
      <div class="text-dark fst-italic small">
        {{ item.subject }} ({{
          currTab == 'inbox-messages'
            ? 'From: ' + item.sender.name
            : recipientNames
        }})
        <span class="forced-breakline">
          {{ messageSentDateTime }}
        </span>
      </div>
      <div v-if="currTab == 'inbox-messages'">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="replyMsg()"
        >
          Reply
        </button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <div
          v-if="currTab == 'inbox-messages'"
          class="text-primary fst-italic small"
        >
          From: {{ item.sender.name }}
        </div>
        <div v-else class="text-primary fst-italic small">
          {{ currTab == 'inbox-messages' ? item.sender.name : recipientNames }}
        </div>
        <div v-if="currTab == 'inbox-messages'">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            @click="replyMsg()"
          >
            Reply
          </button>
        </div>
      </div>
      <div class="d-block my-3 text-primary fst-italic small">
        {{ messageDetails.subject }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div class="ql-editor" v-html="messageDetails.body"></div>
      <!-- eslint-enable -->
    </div>
  </button>
</template>

<script>
import { ref, inject, computed } from 'vue';
import stdLocalTime from '../../../services/lib/localTime';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    currTab: {
      type: String,
      required: true,
    },
  },
  emits: ['reply'],
  setup(props, context) {
    const isExpanded = ref(false);

    const toggleExpanded = async () => {
      await getContent();
      isExpanded.value = !isExpanded.value;
    };

    const localDateTime = stdLocalTime(props.item.sentOnUtc, false);

    const messageApi = inject('api').messageApi();
    const messageDetails = ref({});
    const messageSentDateTime = ref(localDateTime);

    const getContent = async () => {
      const response = await messageApi.getMessageDetails(props.item.id);
      messageDetails.value = response.data;
    };

    const recipientNames = computed(() => {
      let names = 'To: ';
      for (let [i, recipient] of props.item.recipients.entries()) {
        if (i + 1 == props.item.recipients.length) {
          names += recipient.name;
          continue;
        }
        names = names + recipient.name + ', ';
      }
      return names;
    });

    const replyMsg = () => {
      context.emit('reply');
    };

    return {
      isExpanded,
      toggleExpanded,
      getContent,
      messageDetails,
      messageSentDateTime,
      recipientNames,
      replyMsg,
    };
  },
};
</script>
