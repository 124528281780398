<template>
  <div ref="life-plan-container" class="col-md-7 position-relative">
    <h4>Million Dollar Question</h4>
    <p v-if="lifePlanData.millionDollarQuestion">
      {{ lifePlanData.millionDollarQuestion }}
    </p>
    <div v-else class="text-center py-4">
      <span class="text-muted">No response</span>
    </div>
    <h4>Where Do You Want to Retire?</h4>
    <p v-if="lifePlanData.retirement">
      {{ lifePlanData.retirement }}
    </p>
    <div v-else class="text-center py-4">
      <span class="text-muted">No response</span>
    </div>
    <hr />
    <!-- plans -->
    <h4>Immediate Goals</h4>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="lifePlanData.goals && lifePlanData.goals != '<p><br></p>'"
      v-html="lifePlanData.goals"
    ></div>
    <!-- eslint-enable -->
    <div v-else class="text-center py-4">
      <span class="text-muted">No response</span>
    </div>
    <hr />
    <div class="row row-cols-2">
      <div class="col">
        <h5>Year 1</h5>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="lifePlanData.year1 && lifePlanData.year1 != '<p><br></p>'"
          v-html="lifePlanData.year1"
        ></div>
        <!-- eslint-enable -->
        <div v-else class="text-center py-4">
          <span class="text-muted">No response</span>
        </div>
      </div>
      <div class="col">
        <h5>Year 3</h5>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="lifePlanData.year3 && lifePlanData.year3 != '<p><br></p>'"
          v-html="lifePlanData.year3"
        ></div>
        <!-- eslint-enable -->
        <div v-else class="text-center py-4">
          <span class="text-muted">No response</span>
        </div>
      </div>
      <div class="col">
        <h5>Year 5</h5>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="lifePlanData.year5 && lifePlanData.year5 != '<p><br></p>'"
          v-html="lifePlanData.year5"
        ></div>
        <!-- eslint-enable -->
        <div v-else class="text-center py-4">
          <span class="text-muted">No response</span>
        </div>
      </div>
      <div class="col">
        <h5>Year 10</h5>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="lifePlanData.year10 && lifePlanData.year10 != '<p><br></p>'"
          v-html="lifePlanData.year10"
        ></div>
        <!-- eslint-enable -->
        <div v-else class="text-center py-4">
          <span class="text-muted">No response</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';

export default {
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const lifePlanApi = inject('api').lifeplanApi();
    const loading = inject('$loading');

    onMounted(() => {
      refresh();
    });

    const lifePlanContainer = ref(null);
    const lifePlanData = ref({
      goals: '',
      year1: '',
      year3: '',
      year5: '',
      year10: '',
    });
    const refresh = async () => {
      const loader = loading.show({
        container: lifePlanContainer.value,
        isFullPage: false,
      });
      const response = await lifePlanApi.getPatientLifePlan(props.patientId);
      lifePlanData.value = response.data;
      loader.hide();
    };

    return {
      'life-plan-container': lifePlanContainer,
      lifePlanData,
    };
  },
};
</script>
