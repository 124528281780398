<template>
  <div
    ref="contracts-container"
    class="row mt-3 pt-3 border-top position-relative mb-3"
  >
    <div class="col">
      <div class="d-flex justify-content-between">
        <h5>Recent Contracts</h5>
        <a
          v-if="contracts.length > 0"
          class="small"
          style="cursor: pointer"
          @click="$router.push({ name: 'patient-contracts' })"
          >see all</a
        >
      </div>
      <div v-if="contracts.length > 0" class="list-group small lh-sm">
        <a
          v-for="contract in contracts"
          :key="contract.id"
          class="list-group-item list-group-item-action"
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'patient-contracts',
              query: { cid: contract.id },
            })
          "
          >{{ contract.title }}</a
        >
      </div>
      <div v-else class="text-muted">
        You have not created any contracts.
        <router-link :to="{ name: 'patient-contracts' }"
          >Click here</router-link
        >
        to start your first personal contract.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import {
  getLodashArray,
  alterArrayItems,
  getPage,
} from '../../.././../services/lib/clientPaging';

export default {
  setup() {
    const contractsApi = inject('api').contractsApi();
    const loading = inject('$loading');

    const contracts = ref([]);
    const contractsContainer = ref(null);
    onMounted(async () => {
      const loader = loading.show({
        container: contractsContainer.value,
        isFullPage: false,
      });
      const response = await contractsApi.getContractList(true, 0, 50, '');

      const mapped = alterArrayItems(
        getLodashArray(response.data.data),
        function (i) {
          return { ...i, ts: new Date(i.createdOn).getTime() };
        }
      );
      const result = getPage(
        mapped, // items include a generated 'ts' property to sort on
        0, // page to display
        5, // page size
        () => true, // no filter
        'ts', // sorting property
        'desc' // sorting direction
      );
      contracts.value = result.data;
      loader.hide();
    });

    return {
      contracts,
      'contracts-container': contractsContainer,
    };
  },
};
</script>
