<template>
  <!-- tabbed nav for desktop hidden on mobile view -->
  <div class="col responsive-tabs">
    <!-- Tab headings -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          id="home-btn"
          class="nav-link px-1"
          style="cursor: pointer"
          @click="$router.push({ name: 'patients-view' })"
          ><i class="fas fa-home"></i
        ></a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'dimensions-details' }"
          style="cursor: pointer"
          @click="setTab('dimensions-details')"
          >Dimensions</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'journals-details' }"
          style="cursor: pointer"
          @click="setTab('journals-details')"
          >Journals</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'goals-details' }"
          style="cursor: pointer"
          @click="setTab('goals-details')"
          >Goals</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'life-plan-details' }"
          style="cursor: pointer"
          @click="setTab('life-plan-details')"
          >Life Plan</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'contracts-details' }"
          style="cursor: pointer"
          @click="setTab('contracts-details')"
          >Contracts</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'diversions-details' }"
          style="cursor: pointer"
          @click="setTab('diversions-details')"
          >Diversions</a
        >
      </li>
    </ul>

    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content">
      <!-- dimensions -->
      <div
        id="dimensions"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'dimensions-details' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'dimensions-details' }"
              style="cursor: pointer"
              @click="setTab('dimensions-details')"
              >Dimensions</a
            >
          </h5>
        </div>
        <dimensions-details
          v-if="currTab == 'dimensions-details'"
          :patient-id="patientId"
        ></dimensions-details>
      </div>

      <!-- journal -->
      <div
        id="journals"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'journals-details' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'journals-details' }"
              style="cursor: pointer"
              @click="setTab('journals-details')"
              >Journals</a
            >
          </h5>
        </div>
        <journals-details
          v-if="currTab == 'journals-details'"
          :patient-id="patientId"
          @clean="isDirty = false"
          @dirty="isDirty = true"
        ></journals-details>
      </div>
      <!-- goals -->
      <div
        id="goals"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'goals-details' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'goals-details' }"
              style="cursor: pointer"
              @click="setTab('goals-details')"
              >Goals</a
            >
          </h5>
        </div>
        <goals-details
          v-if="currTab == 'goals-details'"
          :patient-id="patientId"
          @dirty="isDirty = true"
          @clean="isDirty = false"
        ></goals-details>
      </div>
      <!-- life plan -->
      <div
        id="life-plan"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'life-plan-details' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'life-plan-details' }"
              style="cursor: pointer"
              @click="setTab('life-plan-details')"
              >Life Plan</a
            >
          </h5>
        </div>
        <life-plan-details
          v-if="currTab == 'life-plan-details'"
          :patient-id="patientId"
        ></life-plan-details>
      </div>
      <!-- contracts -->
      <div
        id="contracts"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'contracts-details' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'contracts-details' }"
              style="cursor: pointer"
              @click="setTab('contracts-details')"
              >Contracts</a
            >
          </h5>
        </div>
        <contracts-details
          v-if="currTab == 'contracts-details'"
          :patient-id="patientId"
        ></contracts-details>
      </div>
      <!-- diversions -->
      <div
        id="diversions"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'diversions-details' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'diversions-details' }"
              style="cursor: pointer"
              @click="setTab('diversions-details')"
              >Diversions</a
            >
          </h5>
        </div>
        <diversions-details
          v-if="currTab == 'diversions-details'"
          :patient-id="patientId"
        ></diversions-details>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import DimensionsDetails from './DimensionsDetails.vue';
import JournalsDetails from './journals-details/JournalsDetails.vue';
import GoalsDetails from './goals-details/GoalsDetails.vue';
import LifePlanDetails from './life-plan-details/LifePlanDetails.vue';
import ContractsDetails from './contracts-details/ContractsDetails.vue';
import DiversionsDetails from './DiversionsDetails.vue';

export default {
  components: {
    DimensionsDetails,
    JournalsDetails,
    GoalsDetails,
    LifePlanDetails,
    ContractsDetails,
    DiversionsDetails,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const patientId = route.params.pid;
    const currTab = ref('dimensions-details');
    const setTab = async (tabName) => {
      if (isDirty.value) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;

      currTab.value = tabName;
      router.replace({ query: { tab: tabName } });
    };

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'dimensions-details';
      }
    });

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      patientId,
      currTab,
      setTab,
      isDirty,
    };
  },
};
</script>
