<template>
  <!-- Page Content title -->
  <div class="row mb-4">
    <div class="col-9">
      <h2>{{ patientName + "'s Personal Contract" }}</h2>
    </div>
    <div class="col">
      <button
        id="close-contract-btn"
        type="button"
        class="btn btn-sm btn-secondary float-end"
        @click="
          $router.push({
            name: 'patient-details',
            params: { pid: patientId },
            query: { tab: 'contracts-details' },
          })
        "
      >
        Close
      </button>
    </div>
  </div>
  <div
    ref="contract-container"
    class="container-lg"
    :class="
      !contract.isSigned ? ['container-lg', 'bg-light', 'py-4', 'mb-5'] : ''
    "
  >
    <div class="row">
      <!-- Title -->
      <div class="col">
        <!-- Contract -->
        <label class="h3 text-muted">{{ contract.title }}</label>
        <div id="contract-editor-div">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="bg-light text-muted ql-editor"
            style="min-height: 25vh"
            v-html="contract.contract"
          ></div>
          <!-- eslint-enable -->
        </div>
      </div>
    </div>

    <!-- Appendix -->
    <div v-if="contract.isSigned" class="py-4">
      <div class="row">
        <div class="col">
          <label for="appendixTextArea" class="text-muted h4">Appendix</label>
          <div id="appendix-editor-div">
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="bg-light text-muted ql-editor"
              style="min-height: 25vh"
              v-html="contract.appendix"
            ></div>
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const loading = inject('$loading');
    const contractsApi = inject('api').contractsApi();
    const profApi = inject('api').profApi();

    const route = useRoute();
    const patientId = ref(route.params.pid);
    const contractId = ref(route.params.cid);

    const contract = ref({});
    const patientName = ref('');
    const contractContainer = ref(null);
    onMounted(async () => {
      const loader = loading.show({
        container: contractContainer.value,
        isFullPage: false,
      });
      const response = await contractsApi.getPatientContract(
        patientId.value,
        contractId.value
      );
      contract.value = response.data;
      const infoResponse = await profApi.getPatientInfo(patientId.value);
      patientName.value =
        infoResponse.data.firstNames + ' ' + infoResponse.data.lastName;
      loader.hide();
    });

    return {
      'contract-container': contractContainer,
      contract,
      patientId,
      patientName,
    };
  },
};
</script>

<style>
/* alex-brush-regular - latin */
@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../../assets/fonts/alex-brush-v13-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../../assets/fonts/alex-brush-v13-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* bonheur-royale-regular - latin */
@font-face {
  font-family: 'Bonheur Royale';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../../assets/fonts/bonheur-royale-v7-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../../assets/fonts/bonheur-royale-v7-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* corinthia-regular - latin */
@font-face {
  font-family: 'Corinthia';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../../assets/fonts/corinthia-v7-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../../assets/fonts/corinthia-v7-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.alex-brush-font {
  font-family: Alex Brush;
}

.bonheur-royale-font {
  font-family: Bonheur Royale;
}

.corinthia-font {
  font-family: Corinthia;
}
</style>
