<template>
  <div ref="patient-home-view" class="container-lg">
    <div class="row">
      <div class="col-md-8">
        <my-todos @goal-update="myGoalsComp.refresh()"></my-todos>
      </div>

      <div class="col">
        <my-goals ref="myGoalsComp"></my-goals>
        <recent-contracts></recent-contracts>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import MyTodos from '../todos/MyTodos.vue';
import MyGoals from './treatment-goals/MyGoals.vue';
import RecentContracts from './contracts/RecentContracts.vue';

export default {
  components: {
    MyTodos,
    MyGoals,
    RecentContracts,
  },
  setup() {
    const myGoalsComp = ref(null);

    const patientHomeView = ref(null);

    onMounted(() => {
      window.scrollTo(0, patientHomeView.value.offsetTop);
    });

    return {
      myGoalsComp,
      'patient-home-view': patientHomeView,
    };
  },
};
</script>
