<template>
  <div ref="active-goals-container" class="col-12 col-lg-6 position-relative">
    <h3>Goals</h3>
    <div ref="goal-list" class="card mb-4">
      <!-- start card header -->
      <div class="card-header pt-3">
        <div class="btn-toolbar">
          <!-- Search box -->
          <div
            class="input-group input-group-sm flex-grow-1 mb-2 me-lg-2 me-xl-0 me-xxl-2"
          >
            <input
              id="search-active-input"
              v-model.trim="filter"
              type="text"
              class="form-control"
              placeholder="Search Goals"
              @keyup.enter="searchGoals"
            />
            <button
              id="search-active-btn"
              class="btn btn-outline-secondary"
              type="button"
              @click="searchGoals"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
          <!-- Sort buttons -->
          <div
            class="btn-group btn-group-sm flex-grow-1 mb-2 ms-2"
            role="group"
          >
            <!-- <button
              id="abc-sort-active-btn"
              type="button"
              class="btn btn-outline-secondary"
              :class="{
                active: sort == 'TitleAsc',
              }"
              @click="abcSort"
            >
              <i class="fas fa-sort-alpha-down"></i>
            </button>
            <button
              id="zyx-sort-active-btn"
              type="button"
              class="btn btn-outline-secondary"
              :class="{
                active: sort == 'TitleDesc',
              }"
              @click="zyxSort"
            >
              <i class="fas fa-sort-alpha-down-alt"></i>
            </button> -->
          </div>
          <div
            class="btn-group btn-group-sm flex-grow-1 mb-2 ms-2"
            role="group"
          >
            <button
              id="incomplete-sort-active-btn"
              type="button"
              class="btn btn-outline-secondary"
              :class="{
                active: sort == 'CompleteAsc',
              }"
              @click="incompleteSort"
            >
              <i class="fas fa-sort-amount-down-alt"></i>
            </button>
            <button
              id="complete-sort-active-btn"
              type="button"
              class="btn btn-outline-secondary"
              :class="{
                active: sort == 'CompleteDesc',
              }"
              @click="completeSort"
            >
              <i class="fas fa-sort-amount-up-alt"></i>
            </button>
          </div>
          <div class="btn-group btn-group-sm mb-2 ms-3" role="group">
            <a
              id="add-goal-btn"
              type="button"
              class="btn btn-mint"
              @click="
                $router.replace({
                  name: 'add-goal',
                  params: { pid: patientId },
                })
              "
              ><i class="fas fa-plus"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- end header -->
      <div
        v-for="(goal, idx) in goals"
        :id="`collapsed-goal-${goal.id}`"
        :key="goal.id"
        class="card-body p-2"
        :class="getGoalClasses(goal, idx)"
      >
        <div class="d-flex align-items-center mt-2">
          <button
            id="toggle-goal-view-btn"
            type="button"
            class="btn flex-grow-1 text-start ps-2 expand-goal-btn"
            data-bs-toggle="collapse"
            :data-bs-target="`#expanded-goal-${goal.id}`"
            @click="toggleGoalView(goal)"
          >
            <h6 class="m-0 p-0 d-flex align-items-md-center">
              <i
                v-if="!expanded.has(goal.id)"
                class="fas fa-chevron-down me-2"
              ></i
              ><i v-else class="fas fa-chevron-up me-2"></i>{{ goal.title }}
            </h6>
          </button>
          <div
            v-if="goal.id != pendingTemplate && goal.id != goalPendingDelete"
            class="btn-group ms-auto"
          >
            <edit-patient-goal
              :dimensions="dimensions"
              :patient-id="patientId"
              :goal="goal"
              @updated="refreshAll"
              @clean="$emit('clean')"
              @dirty="$emit('dirty')"
            ></edit-patient-goal>
            <button
              :id="`clone-goal-${goal.id}-btn`"
              type="button"
              class="btn btn-sm"
              @click="cloneGoal(goal.id)"
            >
              <i class="fas fa-copy"></i>
            </button>
            <button
              :id="`delete-goal-${goal.id}-btn`"
              type="button"
              class="btn btn-sm"
              @click="goalPendingDelete = goal.id"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
            <!-- <goal-to-template :patient-id="patientId" :goal="goal" /> -->
          </div>
          <div
            v-else-if="goal.id == pendingTemplate"
            class="btn-group ms-auto"
            role="group"
          >
            <button
              :id="`confirm-export-goal-${goal.id}-btn`"
              type="button"
              class="btn btn-sm btn-success"
              @click="createTemplate(goal.id)"
            >
              Create Template
            </button>
            <button
              :id="`cancel-export-goal-${goal.id}-btn`"
              type="button"
              class="btn btn-sm btn-secondary"
              @click="pendingTemplate = -1"
            >
              Cancel
            </button>
          </div>
          <div v-else class="btn-group ms-auto" role="group">
            <button
              :id="`confirm-delete-goal-${goal.id}-btn`"
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteGoal(goal.id)"
            >
              Delete
            </button>
            <button
              :id="`cancel-delete-goal-${goal.id}-btn`"
              type="button"
              class="btn btn-sm btn-secondary"
              @click="goalPendingDelete = -1"
            >
              Cancel
            </button>
          </div>
        </div>
        <div class="progress" style="height: 25px">
          <div
            class="progress-bar bg-sky text-end pe-3"
            :style="`width: ${Math.trunc(goal.pctComplete)}%`"
          >
            {{ Math.trunc(goal.pctComplete) }}%
          </div>
        </div>

        <div :id="`expanded-goal-${goal.id}`" class="collapse">
          <div class="p-2">{{ goal.description }}</div>

          <!-- actions assigned to this goal -->
          <div class="border border-2 border-gold bg-light shadow my-2">
            <div class="text-center bg-body">
              <button
                :id="`add-action-${goal.id}-btn`"
                class="btn btn-sm btn-link my-2"
                type="button"
                @click="
                  $router.replace({
                    name: 'add-action',
                    params: { pid: patientId, gid: goal.id },
                  })
                "
              >
                Add an Action
              </button>
            </div>

            <ul class="list-group lh-sm list-group-flush border-bottom">
              <li
                v-for="action in goal.actions"
                :key="action.id"
                class="list-group-item"
                :class="{ 'list-group-item-success': action.isComplete }"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <div class="d-flex align-items-start">
                      <i
                        v-if="action.isComplete"
                        class="fas fa-check-square me-1 mt-1"
                      ></i>
                      <!-- eslint-disable vue/no-v-html -->
                      <div class="ql-editor-z" v-html="action.title"></div>
                      <!-- eslint-enable -->
                    </div>
                    <div
                      v-if="action.description"
                      class="small mb-2 mt-0 text-muted"
                    >
                      {{ action.description }}
                    </div>
                  </div>
                  <div
                    v-if="actionPendingDelete[goal.id] != action.id"
                    class="btn-group ms-auto me-1"
                  >
                    <edit-patient-action
                      :goal-id="goal.id"
                      :dimensions="dimensions"
                      :action="action"
                      :patient-id="patientId"
                      @updated="refreshAll"
                      @clean="$emit('clean')"
                      @dirty="$emit('dirty')"
                    ></edit-patient-action>
                    <button
                      :id="`delete-action-${action.id}-btn`"
                      type="button"
                      class="btn btn-sm"
                      @click="actionPendingDelete[goal.id] = action.id"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                  <div v-else class="btn-group ms-auto" role="group">
                    <button
                      :id="`confirm-delete-btn-${action.id}`"
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="deleteAction(goal.id, action.id)"
                    >
                      Delete
                    </button>
                    <button
                      :id="`cancel-delete-btn-${action.id}`"
                      type="button"
                      class="btn btn-sm btn-secondary"
                      @click="actionPendingDelete[goal.id] = -1"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div class="small d-flex mt-2 mt-md-0">
                  <span class="me-2">{{
                    convertToLocalTime(action.dueDateUtc)
                  }}</span>
                  <span class="badge rounded-pill bg-dark ms-auto">{{
                    action.dimensionName
                  }}</span>
                </div>
              </li>
              <div v-if="goal.actions.length == 0" class="text-center py-1">
                <span class="text-muted"> No actions yet for this goal </span>
              </div>
            </ul>
          </div>
          <!-- end action items -->
        </div>
      </div>
      <div v-if="goals.length == 0" class="text-center py-4">
        <span class="text-muted"> No goals for patient </span>
      </div>
      <!-- Pagination -->
      <div class="card-footer">
        <tbl-page-seln
          v-model="pgNum"
          :total-pages="totalPages"
          :ul-classes="['m-0', 'py-2']"
          @updated="refresh"
        ></tbl-page-seln>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject, reactive, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import stdLocalTime from '../../../../../../../services/lib/localTime';
import TblPageSeln from '../../../../../shared/components/TblPageSeln.vue';
import EditPatientAction from './EditPatientAction.vue';
import EditPatientGoal from './EditPatientGoal.vue';
// import GoalToTemplate from './GoalToTemplate';

export default {
  components: {
    TblPageSeln,
    EditPatientAction,
    EditPatientGoal,
    // GoalToTemplate,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['dirty', 'clean', 'refresh'],
  setup(props, context) {
    const loading = inject('$loading');
    const profApi = inject('api').profApi();
    const generalDataApi = inject('api').generalDataApi();

    // pagination, sorting, and filtering
    const pgNum = ref(1);
    const pgSize = ref(5);
    const filter = ref('');
    const totalPages = ref(0);

    const sort = ref('TitleAsc');

    // initial page setup
    const goals = ref([]);
    const activeGoalsContainer = ref(null);
    const goalList = ref(null);
    const route = useRoute();
    const dimensions = ref([]);
    onMounted(async () => {
      const loader = loading.show({
        container: activeGoalsContainer.value,
        isFullPage: false,
      });
      const response = await generalDataApi.dimensions();
      dimensions.value = response.data;
      loader.hide();
      await refresh();
      // expand goal if the query param is specified
      nextTick(() => {
        const expandedId = route.query.expanded;
        if (typeof expandedId !== 'undefined' && goals.value.length > 0) {
          expandGoal(expandedId);
        }
      });
    });

    const refresh = async () => {
      const loader = loading.show({
        container: activeGoalsContainer.value,
        isFullPage: false,
      });

      const response = await profApi.getPatientGoals(
        props.patientId,
        pgNum.value - 1,
        pgSize.value,
        filter.value,
        sort.value
      );
      goals.value = response.data.data;
      totalPages.value = response.data.totalPages;

      loader.hide();
    };

    const refreshAll = () => {
      refresh();
      context.emit('refresh');
    };

    // expanded/collapsed goal management
    const expanded = ref(new Set());
    const getGoalClasses = (goal, idx) => {
      return {
        active: expanded.value.has(goal.id),
        'border-bottom': idx + 1 < goals.value.length,
        'border-2': idx + 1 < goals.value.length,
      };
    };
    const toggleGoalView = (goal) => {
      if (expanded.value.has(goal.id)) {
        expanded.value.delete(goal.id);
      } else {
        expanded.value.add(goal.id);
      }
    };

    // expand goal if not already expanded
    const expandGoal = (goalId) => {
      if (expanded.value.has(goalId)) return;
      const goal = goalList.value.querySelector(`#collapsed-goal-${goalId}`);
      if (goal) {
        goal.querySelector('.expand-goal-btn').click();
      }
    };

    const convertToLocalTime = (time) => {
      if (time) return stdLocalTime(time, true);
      return 'No due date';
    };

    const searchGoals = () => {
      pgNum.value = 1;
      refresh();
    };

    // sorting
    const abcSort = () => {
      sort.value = 'TitleAsc';
      pgNum.value = 1;
      filter.value = '';
      refresh();
    };
    const zyxSort = () => {
      sort.value = 'TitleDesc';
      pgNum.value = 1;
      filter.value = '';
      refresh();
    };
    const incompleteSort = () => {
      sort.value = 'CompleteAsc';
      pgNum.value = 1;
      filter.value = '';
      refresh();
    };
    const completeSort = () => {
      sort.value = 'CompleteDesc';
      pgNum.value = 1;
      filter.value = '';
      refresh();
    };

    const pendingTemplate = ref(-1);
    const createTemplate = async (goalId) => {
      await profApi.templateFromPatientGoal(props.patientId, goalId);
      pendingTemplate.value = -1;
      context.emit('refresh');
    };

    const cloneGoal = async (goalId) => {
      await profApi.clonePatientGoal(props.patientId, goalId);
      refreshAll();
    };

    const goalPendingDelete = ref(-1);
    const deleteGoal = async (goalId) => {
      await profApi.deletePatientGoal(props.patientId, goalId);
      goalPendingDelete.value = -1;
      refreshAll();
    };

    const actionPendingDelete = reactive({});
    const deleteAction = async (goalId, actionId) => {
      await profApi.deletePatientAction(props.patientId, goalId, actionId);
      actionPendingDelete[goalId] = -1;
      refreshAll();
    };

    return {
      'active-goals-container': activeGoalsContainer,
      'goal-list': goalList,
      pgNum,
      totalPages,
      filter,
      goals,
      expanded,
      getGoalClasses,
      convertToLocalTime,
      toggleGoalView,
      refresh,
      refreshAll,
      searchGoals,
      sort,
      abcSort,
      zyxSort,
      incompleteSort,
      completeSort,
      cloneGoal,
      createTemplate,
      pendingTemplate,
      goalPendingDelete,
      deleteGoal,
      actionPendingDelete,
      deleteAction,
      dimensions,
      expandGoal,
    };
  },
};
</script>
