<template>
  <button
    id="pushed-prompt-expansion-btn"
    type="button"
    class="list-group-item list-group-item-action d-flex flex-column border border-start-3"
    :class="entryBorderClass(item.promptType)"
    :disabled="is_disabled"
    @click="toggleExpanded()"
  >
    <div class="d-flex justify-content-between align-items-center w-100">
      <div>
        <small v-if="responded_on" class="d-block text-secondary">{{
          responded_on
        }}</small>
        <i
          :class="`${entryTextClass(item.promptType)} fas fa-${entryIconType(
            item.promptType
          )} fa-xs`"
        ></i>
        {{ item.prompt }}
      </div>
      <div>
        <i
          v-if="item.hasResponse && item.sharedResponse"
          class="text-success fas fa-eye fa-lg"
        ></i>
        <i
          v-else-if="item.hasResponse && !item.sharedResponse"
          icon="eye-slash"
          size="lg"
          class="fas fa-eye-slash fa-lg"
        ></i>
      </div>
    </div>
    <div v-if="is_expanded" class="pt-3">
      <!-- eslint-disable vue/no-v-html -->
      <div class="ql-editor" v-html="item.sharedResponse"></div>
      <!-- eslint-enable -->
    </div>
  </button>
</template>

<script>
import { ref } from 'vue';
import stdLocalTime from '../../../../../../services/lib/localTime';
import {
  entryBorderClass,
  entryTextClass,
  entryIconType,
} from '../../../../../../services/lib/journallingLib';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const is_disabled = ref(
      !props.item.hasResponse || !props.item.sharedResponse
    );
    const responded_on = ref(
      props.item.hasResponse ? stdLocalTime(props.item.respondedOnUtc) : null
    );
    const is_expanded = ref(false);

    const toggleExpanded = () => {
      is_expanded.value = !is_expanded.value;
    };

    return {
      entryBorderClass,
      entryTextClass,
      entryIconType,
      toggleExpanded,
      is_disabled,
      responded_on,
      is_expanded,
    };
  },
};
</script>
