<template>
  <bh-header></bh-header>
  <router-view></router-view>
</template>

<script>
import BhHeader from './shared/BhHeader.vue';

export default {
  components: {
    BhHeader,
  },
  setup() {},
};
</script>
