<template>
  <div ref="pushed-prompts-container" class="col-md-6 col-md position-relative">
    <div class="card mb-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        Custom Prompts
        <button
          id="add-prompt-btn"
          class="btn btn-sm btn-link"
          data-bs-toggle="modal"
          data-bs-target="#dlg-add-prompt"
          @click="resetEntry()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div v-if="pushedPrompts.length == 0" class="text-center py-4">
        <span class="text-muted"> No prompts for patient </span>
      </div>
      <ul v-else class="list-group list-group-flush lh-sm mb-3">
        <prompt-entry
          v-for="item in pushedPrompts"
          :key="item.id"
          :item="item"
        />
      </ul>
      <tbl-page-seln
        v-model="pgNum"
        :total-pages="totalPages"
        @updated="refresh"
      />
    </div>
  </div>
  <!-- Add New Prompt Modal -->
  <div id="dlg-add-prompt" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Journal Prompt</h5>
        </div>
        <div class="modal-body px-4">
          <form @submit.prevent="doSubmit">
            <!-- Enter prompt type -->
            <div class="mb-3">
              <div class="form-check mb-2">
                <input
                  id="thought-prompt-type"
                  v-model="addPromptType"
                  class="form-check-input"
                  type="radio"
                  value="ThoughtProfPrompt"
                />
                <label
                  class="form-check-label ps-3"
                  :class="entryTextClass('ThoughtProfPrompt')"
                  for="thought-prompt-type"
                >
                  <i
                    :class="`fas fa-${entryIconType(
                      'ThoughtProfPrompt'
                    )} fa-xs`"
                  ></i>
                  Daily Thought Prompt
                </label>
              </div>
              <div class="form-check">
                <input
                  id="trauma-prompt-type"
                  v-model="addPromptType"
                  class="form-check-input"
                  type="radio"
                  value="TraumaProfPrompt"
                />
                <label
                  class="form-check-label ps-3"
                  :class="entryTextClass('TraumaProfPrompt')"
                  for="thought-prompt-type"
                >
                  <i
                    :class="`fas fa-${entryIconType('TraumaProfPrompt')} fa-xs`"
                  ></i>
                  Trauma Prompt
                </label>
              </div>
            </div>
            <!-- Enter prompt text -->
            <div class="mb-3">
              <label for="prompt-text" class="form-label"> Prompt </label>
              <input
                id="prompt-text"
                v-model="addPromptText"
                type="text"
                class="form-control"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="add-prompt-submit"
            ref="do-add-prompt"
            type="submit"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            :disabled="!addPromptText"
            @click="addPrompt"
          >
            Add Prompt
          </button>
          <button
            id="add-prompt-cancel"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="$emit('clean')"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PromptEntry from './PromptEntry.vue';
import TblPageSeln from '../../../../shared/components/TblPageSeln.vue';
import {
  entryTextClass,
  entryIconType,
} from '../../../../../../services/lib/journallingLib';

export default {
  components: { PromptEntry, TblPageSeln },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['dirty', 'clean'],
  setup(props, context) {
    dayjs.extend(utc);

    const loading = inject('$loading');
    const profApi = inject('api').profApi();

    // initial page setup
    const pgNum = ref(1);
    const pgSz = ref(5);
    const pushedPromptsContainer = ref(null);
    const pushedPrompts = ref([]);
    const totalPages = ref(0);

    // add prompt data
    const addPromptType = ref('ThoughtProfPrompt');
    const addPromptText = ref('');

    const DoAddPrompt = ref(null);

    onMounted(() => {
      refresh();
    });

    const refresh = async () => {
      const loader = loading.show({
        container: pushedPromptsContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getPrompts(
        props.patientId,
        pgNum.value - 1,
        pgSz.value
      );
      totalPages.value = response.data.totalPages;
      pushedPrompts.value = response.data.data;
      loader.hide();
    };

    const resetEntry = () => {
      addPromptType.value = 'ThoughtProfPrompt';
      addPromptText.value = '';
      context.emit('dirty');
    };

    const doSubmit = () => {
      DoAddPrompt.value.click();
    };

    const addPrompt = async () => {
      console.log('prompt type', addPromptType.value);
      const expires = dayjs.utc().add(7, 'day').format();
      await profApi.addPrompt(
        props.patientId,
        addPromptType.value,
        expires,
        addPromptText.value
      );
      context.emit('clean');
      await refresh();
    };

    return {
      'pushed-prompts-container': pushedPromptsContainer,
      'do-add-prompt': DoAddPrompt,
      pushedPrompts,
      pgNum,
      totalPages,
      addPromptType,
      addPromptText,
      resetEntry,
      addPrompt,
      doSubmit,
      entryTextClass,
      entryIconType,
      refresh,
    };
  },
};
</script>
