<template>
  <div class="col">
    <div ref="journal-entries-container" class="card position-relative">
      <div class="card-header">
        Shared Journal Entries
        <form class="d-flex" @submit.prevent="refresh()">
          <div class="input-group input-group-sm my-2">
            <input
              id="shared-prompts-filter-input"
              v-model="filter"
              type="text"
              class="form-control"
              placeholder="Search Journal Entries"
              @keydown.enter="refresh()"
            />
            <button
              id="search-shared-entries-btn"
              class="btn btn-outline-secondary"
              type="button"
              @click="refresh()"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div v-if="entries.length == 0" class="text-center py-4">
        <span class="text-muted"> No Shared Journal Entries </span>
      </div>
      <div v-else class="list-group list-group-flush mb-3 lh-sm">
        <journal-entry v-for="item in entries" :key="item.id" :item="item" />
      </div>
      <!-- Pagination -->
      <tbl-page-seln
        v-model="pgNum"
        :total-pages="totalPages"
        @updated="refresh()"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TblPageSeln from '../../../../shared/components/TblPageSeln.vue';
import JournalEntry from './JournalEntry.vue';

export default {
  components: { JournalEntry, TblPageSeln },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    dayjs.extend(utc);

    const loading = inject('$loading');
    const journalApi = inject('api').journalApi();

    // initial page setup
    const pgNum = ref(1);
    const pgSz = ref(5);
    const filter = ref(null);
    const journalEntriesContainer = ref(null);
    const entries = ref([]);
    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: journalEntriesContainer.value,
        isFullPage: false,
      });
      const response = await journalApi.getSharedJournalEntries(
        props.patientId,
        pgNum.value - 1,
        pgSz.value,
        filter.value
      );
      totalPages.value = response.data.totalPages;
      entries.value = response.data.data;
      loader.hide();
    };

    onMounted(async () => {
      await refresh();
    });

    return {
      'journal-entries-container': journalEntriesContainer,
      pgNum,
      filter,
      entries,
      totalPages,
      refresh,
    };
  },
};
</script>
