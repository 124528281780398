<template>
  <button
    id="shared-entry-expansion-btn"
    type="button"
    class="list-group-item list-group-item-action border border-start-3"
    :class="entryBorderClass(item.entryType)"
    :disabled="!has_prompt"
    @click="toggleExpanded()"
  >
    <small v-if="updated_on" class="d-block text-secondary"
      >{{ updated_on }}
      <span v-if="has_prompt" class="ms-3">
        <i
          :class="`fas fa-xs fa-${entryIconType(
            item.entryType
          )} ${entryTextClass(item.entryType)}`"
        ></i>
      </span>
    </small>
    <div v-if="!is_expanded">
      <!-- eslint-disable vue/no-v-html -->
      <div class="ql-editor" v-html="item.response"></div>
      <!-- eslint-enable -->
    </div>
    <div v-else>
      <span class="d-block my-3">
        {{ item.prompt }}
      </span>
      <!-- eslint-disable vue/no-v-html -->
      <div class="ql-editor" v-html="item.response"></div>
      <!-- eslint-enable -->
    </div>
  </button>
</template>

<script>
import { ref } from 'vue';
import stdLocalTime from '../../../../../../services/lib/localTime';
import {
  entryBorderClass,
  entryTextClass,
  entryIconType,
} from '../../../../../../services/lib/journallingLib';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const updated_on = ref(
      props.item.lastUpdatedUtc ? stdLocalTime(props.item.lastUpdatedUtc) : null
    );
    const has_prompt = ref(props.item.prompt != null);
    const is_expanded = ref(false);

    const toggleExpanded = () => {
      is_expanded.value = !is_expanded.value;
    };

    return {
      updated_on,
      has_prompt,
      is_expanded,
      entryBorderClass,
      entryTextClass,
      entryIconType,
      toggleExpanded,
    };
  },
};
</script>
