<template>
  <div class="container-lg p-4 collapse show">
    <div class="row mb-3">
      <div class="col">
        <div class="d-flex justify-content-between">
          <div class="form-check form-check-sm">
            <input
              id="unreadOnly"
              v-model="unreadOnly"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" for="makeGlobal">
              Unread Only
            </label>
          </div>
          <select v-model="senderFilter" class="form-select-sm">
            <option :value="''">Filter by Sender</option>
            <option
              v-for="contact in contacts"
              :key="contact.id"
              :value="contact.id"
            >
              {{ contact.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div ref="inbox-container" class="card mb-4 position-relative">
          <div class="card-header">
            <form class="d-flex align-items-center" @submit.prevent="refresh">
              <div class="input-group input-group-sm flex-grow-1 my-2">
                <input
                  id="messages-filter-input"
                  v-model.trim="filter"
                  type="text"
                  class="form-control"
                  placeholder="Search Messages"
                  @keydown.enter="searchMessages"
                />
                <button
                  id="search-messages-btn"
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="searchMessages"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
              <div class="btn-group btn-group-sm flex-grow-0 ms-3" role="group">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{ active: !sortDesc }"
                  @click="abcSort"
                >
                  <i class="fas fa-sort-up"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{ active: sortDesc }"
                  @click="zyxSort"
                >
                  <i class="fas fa-sort-down"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link ms-1"
                  @click="refresh"
                >
                  <i class="fas fa-sync-alt"></i>
                </button>
              </div>
            </form>
          </div>
          <div v-if="messages.length == 0" class="text-center py-4">
            <span class="text-muted">
              No {{ currTab == 'sent-messages' ? 'Sent' : '' }} Messages
            </span>
          </div>
          <div v-else class="list-group list-group-flush mb-3 lh-sm">
            <message-content
              v-for="message in messages"
              :key="message.id"
              :item="message"
              :curr-tab="currTab"
              @reply="$emit('reply', message)"
            />
          </div>
          <!-- Pagination -->
          <tbl-page-seln
            v-model="pgNum"
            :total-pages="totalPages"
            @updated="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MessageContent from './MessageContent.vue';
import TblPageSeln from '../shared/components/TblPageSeln.vue';

export default {
  components: {
    MessageContent,
    TblPageSeln,
  },
  props: {
    currTab: {
      type: String,
      required: true,
    },
  },
  emits: ['reply'],
  setup() {
    const messageApi = inject('api').messageApi();
    const loading = inject('$loading');
    const route = useRoute();
    const router = useRouter();

    const unreadOnly = ref(true);
    const pgNum = ref(1);
    const pgSz = ref(5);
    const filter = ref('');
    const senderFilter = ref('');
    const sortDesc = ref(true);
    const totalPages = ref(0);
    const messages = ref([]);
    const contacts = ref([]);
    const inboxContainer = ref(null);

    onMounted(async () => {
      const loader = loading.show({
        container: inboxContainer.value,
        isFullPage: false,
      });
      const response = await messageApi.getContacts();
      contacts.value = response.data;
      if (route.query.sender) {
        senderFilter.value = parseInt(route.query.sender);
        let query = Object.assign({}, route.query);
        delete query.sender;
        router.replace(query);
      }
      loader.hide();
      refresh();
    });

    const refresh = async () => {
      const loader = loading.show({
        container: inboxContainer.value,
        isFullPage: false,
      });
      const response = await messageApi.getInbox(
        pgNum.value - 1,
        pgSz.value,
        filter.value,
        unreadOnly.value,
        sortDesc.value,
        senderFilter.value
      );
      totalPages.value = response.data.totalPages;
      messages.value = response.data.data;

      // clean up no-longer-needed query params, if present
      // let query = Object.assign({}, route.query);
      // if (route.query.currTab) delete query.currTab;
      // if (route.query.mid) delete query.mid;
      // if (route.query.rid) delete query.rid;
      // router.replace(query);

      loader.hide();
    };

    watch(unreadOnly, () => {
      refresh();
    });

    watch(senderFilter, () => {
      refresh();
    });

    const searchMessages = () => {
      pgNum.value = 1;
      refresh();
    };

    const abcSort = () => {
      sortDesc.value = false;
      refresh();
    };

    const zyxSort = () => {
      sortDesc.value = true;
      refresh();
    };

    return {
      'inbox-container': inboxContainer,
      messages,
      refresh,
      pgNum,
      pgSz,
      filter,
      totalPages,
      searchMessages,
      unreadOnly,
      sortDesc,
      abcSort,
      zyxSort,
      contacts,
      senderFilter,
    };
  },
};
</script>
