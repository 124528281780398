<template>
  <!-- main nav -->
  <div class="container-lg bg-white border border-mint rounded shadow mb-4">
    <div class="row align-items-center py-2">
      <div class="col-md-6 col-lg-5 col-xl-4 mb-3 mb-md-0">
        <div class="d-flex align-items-center">
          <!-- therapist info -->
          <div
            id="patient-profile-pic"
            class="circle-profile shadow me-3 flex-shrink-0"
            style="cursor: pointer"
            :style="`background-image: url(${profPicUrl})`"
            @click="
              $router.push({
                name: 'pro-view-patient',
                params: { uid: patientId },
              })
            "
          ></div>

          <div class="d-flex flex-grow-1 flex-column">
            <p class="h5">
              {{ acctInfo.firstNames + ' ' + acctInfo.lastName }}
            </p>

            <div class="btn-toolbar align-items-center">
              <p class="small p-0 m-0 lh-sm ms-2 ms-xl-0 d-flex flex-md-column">
                <b class="text-muted">Last Login:</b> {{ lastLoginLocal }}
              </p>
            </div>
          </div>
        </div>

        <!-- EHR Links -->
        <div
          class="d-grid gap-2 d-block d-sm-flex justify-content-sm-center justify-content-md-center mt-2"
        >
          <!-- <button
            id="treatment-plan-btn"
            class="btn btn-sm btn-primary"
            type="button"
          >
            Treatment Plan
            <i class="fas fa-external-link-square-alt ms-2"></i>
          </button>
          <button id="billing-btn" class="btn btn-sm btn-primary" type="button">
            Billing <i class="fas fa-file-invoice-dollar ms-2"></i>
          </button>
          <button
            id="start-session-btn"
            class="btn btn-sm btn-primary"
            type="button"
          >
            Start Session<i class="fas fa-video ms-2"></i>
          </button> -->
        </div>
      </div>
      <!-- progress -->
      <div
        ref="patient-overview-container"
        class="col text-center position-relative"
      >
        <h5 class="p-0">This Week At a Glance</h5>
        <div class="row row-cols-2 row-cols-sm-5 g-2 text-left small">
          <div class="col">
            <button
              id="mail-btn"
              class="d-flex flex-column p-3 btn btn-primary w-100 align-items-center"
              type="button"
              @click="
                $router.push({
                  name: 'pro-msg',
                  query: { sender: patientId },
                })
              "
            >
              <h6>Mail</h6>
              <h2>{{ overviewData.mail }}</h2>
            </button>
          </div>
          <div class="col">
            <button
              id="diversion-btn"
              type="button"
              class="d-flex flex-column p-3 btn btn-primary w-100 align-items-center"
              @click="setTab('diversions-details')"
            >
              <h6>Diversion</h6>
              <h2>{{ overviewData.diversions }}</h2>
            </button>
          </div>
          <div class="col">
            <button
              id="goals-btn"
              type="button"
              class="d-flex flex-column p-3 btn btn-primary w-100 align-items-center"
              @click="setTab('goals-details')"
            >
              <h6>Goals</h6>
              <h2>{{ overviewData.goals }}</h2>
            </button>
          </div>
          <div class="col">
            <button
              id="journal-btn"
              type="button"
              class="d-flex flex-column p-3 btn btn-primary w-100 align-items-center"
              @click="setTab('journals-details')"
            >
              <h6>Journal</h6>
              <h2>{{ overviewData.journals }}</h2>
            </button>
          </div>
          <div class="col">
            <button
              id="tasks-btn"
              type="button"
              class="d-flex flex-column p-3 btn btn-primary w-100 align-items-center"
              @click="setTab('goals-details')"
            >
              <h6>Tasks</h6>
              <h2>{{ overviewData.tasks }}</h2>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-lg mb-4">
    <div ref="router-view-container" class="row">
      <router-view v-slot="{ Component }">
        <component :is="Component" ref="child-comp"></component>
      </router-view>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import stdLocalTime from '../../../../../services/lib/localTime';

export default {
  setup() {
    const profApi = inject('api').profApi();
    const loading = inject('$loading');
    const route = useRoute();
    const router = useRouter();
    const patientId = route.params.pid;

    onMounted(() => {
      refresh();
    });

    const patientOverviewContainer = ref(null);
    const overviewData = ref({});
    const acctInfo = ref({});
    const refresh = async () => {
      const loader = loading.show({
        container: patientOverviewContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getPatientOverview(patientId);
      overviewData.value = response.data;
      const acctInfoResponse = await profApi.getPatientInfo(patientId);
      acctInfo.value = acctInfoResponse.data;
      loader.hide();
    };

    const profPicUrl = computed(() => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${patientId}`;
    });

    const lastLoginLocal = computed(() => {
      if (!acctInfo.value.lastLoginUtc) {
        return 'Never';
      }
      return stdLocalTime(acctInfo.value.lastLoginUtc);
    });

    const childComp = ref(null);
    const routerViewContainer = ref(null);
    const setTab = (tabName) => {
      if (route.name == 'patient-details') {
        childComp.value.setTab(tabName);
      } else {
        router.push({
          name: 'patient-details',
          query: { tab: tabName },
        });
      }

      window.scrollTo(0, routerViewContainer.value.offsetTop);
    };

    return {
      'patient-overview-container': patientOverviewContainer,
      profPicUrl,
      lastLoginLocal,
      overviewData,
      acctInfo,
      patientId,
      'child-comp': childComp,
      setTab,
      'router-view-container': routerViewContainer,
    };
  },
};
</script>
