<template>
  <div id="collapse-D" class="collapse show">
    <div ref="personal-contracts-container" class="card-body position-relative">
      <div class="row mb-4">
        <div class="col ms-auto">
          <div class="input-group">
            <input
              v-model="filter"
              type="text"
              class="form-control"
              placeholder="Search Contracts"
              @keyup.enter="searchContracts"
            />
            <button
              class="btn btn-secondary"
              type="button"
              @click="searchContracts"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table
              class="table table-hover table-striped align-middle lh-sm border-3 border-bottom"
            >
              <thead>
                <tr>
                  <th scope="col">Contract Title</th>
                  <th scope="col">Saved</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="contract in contracts"
                  :key="contract.id"
                  style="cursor: pointer"
                  @click="
                    $router.push({
                      name: 'patient-contract',
                      params: { pid: patientId, cid: contract.id },
                    })
                  "
                >
                  <td>{{ contract.title }}</td>
                  <td class="small">
                    {{ convertToLocalTime(contract.createdOn) }}
                  </td>
                  <td v-if="contract.isSigned" class="text-success">
                    <i class="fas fa-file-signature mx-auto"></i>
                  </td>
                  <td v-else class="text-secondary text-opacity-25">
                    <i class="fas fa-file-signature"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <tbl-page-seln
            v-model="pgNum"
            :total-pages="totalPages"
            :ul-classes="['mb-sm-0']"
            @updated="loadPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import stdLocalTime from '../../../../../../services/lib/localTime';
import TblPageSeln from '../../../../shared/components/TblPageSeln.vue';

export default {
  components: {
    TblPageSeln,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const loading = inject('$loading');
    const contractsApi = inject('api').contractsApi();

    // initial page setup
    const contracts = ref([]);
    const totalPages = ref(0);
    const pgNum = ref(1);
    const pgSz = ref(5);
    const filter = ref(null);
    const personalContractsContainer = ref(null);
    onMounted(async () => {
      loadPage();
    });

    const loadPage = async () => {
      const loader = loading.show({
        container: personalContractsContainer.value,
        isFullPage: false,
      });

      const response = await contractsApi.getPatientContracts(
        props.patientId,
        pgNum.value - 1,
        pgSz.value,
        filter.value
      );
      contracts.value = response.data.data;
      totalPages.value = response.data.totalPages;

      loader.hide();
    };

    const convertToLocalTime = (time) => {
      return stdLocalTime(time);
    };

    const searchContracts = () => {
      pgNum.value = 1;
      loadPage();
    };

    return {
      'personal-contracts-container': personalContractsContainer,
      contracts,
      totalPages,
      convertToLocalTime,
      pgNum,
      filter,
      loadPage,
      searchContracts,
    };
  },
};
</script>
