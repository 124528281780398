<template>
  <base-modal
    ref="patient-action-modal"
    :show-btn-classes="{ btn: true, 'btn-sm': true }"
    @modal-opened="refreshModalData"
    @exit="$emit('clean')"
  >
    <template #show-btn-content>
      <i class="fas fa-edit"></i>
    </template>
    <template #header-title>Edit Action</template>
    <template #body
      ><div class="row">
        <div class="col-md-6">
          <div class="row g-2">
            <div class="col-12">
              <div id="action-rte-container">
                <rich-text-editor
                  v-model:data="localAction"
                  :data-id="editCounter"
                  container-id="action-rte-container"
                  property="title"
                  height="10vh"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <select
                  :id="`action-${action.id}-dimension`"
                  v-model="localAction.dimensionId"
                  class="form-select"
                >
                  <option
                    v-for="dimension in dimensions"
                    :key="dimension.id"
                    :value="dimension.id"
                  >
                    {{ dimension.text }}
                  </option>
                </select>
                <label :for="`action-${action.id}-dimension`"
                  >Action Dimension</label
                >
              </div>
            </div>
            <div class="col-12">
              <form class="form-floating mb-3">
                <input
                  :id="`${action.id}-due-date`"
                  v-model="localAction.dueOnLocal"
                  type="date"
                  class="form-control"
                  placeholder="Due Date"
                />
                <label :for="`${action.id}-due-date`"
                  >Due Date (optional)</label
                >
              </form>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea
                  :id="`action-${action.id}-due-date`"
                  v-model="localAction.description"
                  class="form-control"
                  placeholder="LOptional Description"
                ></textarea>
                <label :for="`action-${action.id}-due-date`"
                  >Description (optional)</label
                >
              </div>
            </div>
          </div>
        </div>
      </div></template
    >
    <template #footer>
      <button
        :id="`close-action-modal-${action.id}`"
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        :id="`save-action-modal-${action.id}`"
        :disabled="!localAction.title || localAction.title == '<p><br></p>'"
        type="button"
        class="btn btn-success"
        @click="editAction"
      >
        Save &amp; Close
      </button>
    </template>
  </base-modal>
</template>

<script>
import { ref, inject } from 'vue';
import RichTextEditor from '../../../../../shared/components/RichTextEditor.vue';
import BaseModal from '../../../../../shared/UI/BaseModal.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default {
  components: {
    RichTextEditor,
    BaseModal,
  },
  props: {
    dimensions: {
      type: Array,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    goalId: {
      type: Number,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['updated', 'clean', 'dirty'],
  setup(props, context) {
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);

    // create copy of action, to be edited
    const localAction = ref({});

    // save changes
    const profApi = inject('api').profApi();
    const patientActionModal = ref(null);
    const editAction = async () => {
      localAction.value.dueOnUtc = !localAction.value.dueOnLocal
        ? null
        : dayjs.utc(
            `${localAction.value.dueOnLocal} 11:59 PM`,
            'YYYY-MM-DD HH:mm A'
          );

      await profApi.editPatientAction(
        props.patientId,
        props.goalId,
        localAction.value
      );
      context.emit('updated');
      context.emit('clean');
      patientActionModal.value.toggleModal();
    };

    const toggleModal = () => {
      patientActionModal.value.toggleModal();
    };

    const editCounter = ref(0);
    const refreshModalData = () => {
      localAction.value = JSON.parse(JSON.stringify(props.action));
      localAction.value.dueOnLocal = localAction.value.dueDateUtc
        ? dayjs(localAction.value.dueDateUtc).local().format('YYYY-MM-DD')
        : null;
      editCounter.value += 1;
      context.emit('dirty');
    };

    const cancel = () => {
      context.emit('clean');
      toggleModal();
    };

    return {
      localAction,
      'patient-action-modal': patientActionModal,
      editAction,
      toggleModal,
      refreshModalData,
      editCounter,
      cancel,
    };
  },
};
</script>
