<template>
  <div id="collapse-A" class="collapse show">
    <div class="card-body">
      <div class="row">
        <pushed-prompts
          :patient-id="patientId"
          @dirty="$emit('dirty')"
          @clean="$emit('clean')"
        />
        <shared-journal-entries :patient-id="patientId" />
      </div>
    </div>
  </div>
</template>

<script>
import PushedPrompts from './PushedPrompts.vue';
import SharedJournalEntries from './SharedJournalEntries.vue';

export default {
  components: { PushedPrompts, SharedJournalEntries },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['clean', 'dirty'],
  setup() {},
};
</script>
