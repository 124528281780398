<template>
  <div class="small text-center d-flex justify-content-start pb-4">
    <router-link
      id="back-btn"
      :to="{
        name: 'patient-details',
        params: { pid: patientId },
        query: { tab: 'goals-details', expanded: goalId },
      }"
      class="btn btn-sm btn-link link-secondary"
    >
      <i class="fas fa-angle-double-left"></i>
      Back to Patient Goals</router-link
    >
  </div>
  <div ref="add-action-container" class="row position-relative">
    <!-- Create new one off action with option to save to library -->
    <div class="col-md-6 mb-4">
      <h2>Add New Action</h2>
      <div class="row g-2">
        <div class="col-12">
          <div id="action-rte-container">
            <rich-text-editor
              v-model:data="nameObj"
              container-id="action-rte-container"
              property="actionName"
              height="10vh"
              @dirty="isDirty = true"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-3">
            <select
              id="actionDimension"
              v-model="dimensionId"
              class="form-select"
              @input="isDirty = true"
            >
              <option :value="-1"></option>
              <option
                v-for="dimension in dimensions"
                :key="dimension.id"
                :value="dimension.id"
              >
                {{ dimension.text }}
              </option>
            </select>
            <label for="actionDimension">Action Dimension</label>
          </div>
        </div>
        <div class="col">
          <form class="form-floating mb-3">
            <input
              id="dueDate"
              v-model="dueDate"
              type="date"
              class="form-control"
              placeholder="Due Date"
              @input="isDirty = true"
            />
            <label for="dueDate">Due Date</label>
          </form>
        </div>
        <div class="col-12">
          <div class="form-floating mb-3">
            <textarea
              id="actionDescription"
              v-model="description"
              class="form-control"
              placeholder="Optional Description"
              @input="isDirty = true"
            ></textarea>
            <label for="actionDescription">Description (optional)</label>
          </div>
        </div>
      </div>
      <!-- <div class="col">
        <div class="form-check mb-3">
          <input
            id="makeGlobal"
            v-model="addToTemplates"
            class="form-check-input"
            type="checkbox"
          />
          <label class="form-check-label" for="makeGlobal">
            Add to Saved Actions
          </label>
        </div>
      </div> -->
      <div class="col text-end">
        <button
          id="add-action-btn"
          :disabled="
            !nameObj.actionName ||
            nameObj.actionName == '<p><br></p>' ||
            dimensionId == -1 ||
            !dueDate
          "
          type="button"
          class="btn btn-primary"
          @click="addNewAction"
        >
          {{
            addToTemplates ? 'Save Action & Add to Goal' : 'Add Action to Goal'
          }}
        </button>
      </div>
    </div>
    <!-- Added an action from Library -->
    <!-- <div class="col-md-6">
      <h2>Add Library Action</h2>
      <div class="row g-2">
        <div class="col-12">
          <div class="form-floating mb-3">
            <select
              id="selectSavedAction"
              v-model="selectedActionId"
              class="form-select"
              @input="isDirty = true"
            >
              <option :value="-1"></option> -->
              <!-- eslint-disable vue/no-v-html -->
              <!-- <option
                v-for="action in templateActions"
                :key="action.id"
                :value="action.id"
                class="ql-editor"
                v-html="action.title"
              ></option> -->
              <!-- eslint-enable -->
            <!-- </select>
            <label for="selectSavedAction">Choose Action</label>
          </div>
        </div>
        <div class="col">
          <div class="form-floating mb-3">
            <select
              id="actionDefaultDimension"
              v-model="templateDimensionId"
              class="form-select"
              @input="isDirty = true"
            >
              <option :value="-1"></option>
              <option
                v-for="dimension in dimensions"
                :key="dimension.id"
                :value="dimension.id"
              >
                {{ dimension.text }}
              </option>
            </select>
            <label for="actionDefaultDimension">Action Dimension</label>
          </div>
        </div>
        <div class="col">
          <form class="form-floating mb-3">
            <input
              id="templateDueDate"
              v-model="templateDueDate"
              type="date"
              class="form-control"
              placeholder="Optional Due Date"
              @input="isDirty = true"
            />
            <label for="templateDueDate">Due Date (optional)</label>
          </form>
        </div>
      </div>
      <div class="col text-end">
        <button
          id="use-action-template-btn"
          :disabled="selectedActionId == -1 || templateDimensionId == -1"
          type="button"
          class="btn btn-primary"
          @click="useTemplateAction"
        >
          Add Action to Goal
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  watch,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import RichTextEditor from '../../../../../shared/components/RichTextEditor.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default {
  components: {
    RichTextEditor,
  },
  setup() {
    const profApi = inject('api').profApi();
    const route = useRoute();
    const patientId = ref(route.params.pid);
    const goalId = ref(route.params.gid);
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);

    // get dimension data and library actions
    const loading = inject('$loading');
    const addActionContainer = ref(null);
    const dimensions = ref([]);
    const templateActions = ref([]);
    const generalDataApi = inject('api').generalDataApi();
    onMounted(async () => {
      const loader = loading.show({
        container: addActionContainer.value,
        isFullPage: false,
      });
      const response = await generalDataApi.dimensions();
      dimensions.value = response.data;
      const actionsResponse = await profApi.getTemplateActions();
      templateActions.value = actionsResponse.data;
      loader.hide();
    });

    // add a new action to goal
    const router = useRouter();
    const nameObj = ref({ actionName: '<p><br></p>' });
    const description = ref('');
    const dimensionId = ref(-1);
    const dueDate = ref('');
    const addToTemplates = ref(false);
    const addNewAction = async () => {
      if (
        !nameObj.value.actionName ||
        nameObj.value.actionName == '<p><br></p>' ||
        dimensionId.value == -1 ||
        !dueDate.value
      )
        return;
      const loader = loading.show({
        container: addActionContainer.value,
        isFullPage: false,
      });
      const utcDueDate = !dueDate.value
        ? null
        : dayjs(`${dueDate.value} 11:59 PM`, 'YYYY-MM-DD HH:mm A').utc();
      await profApi.addCustomPatientAction(
        patientId.value,
        goalId.value,
        nameObj.value.actionName,
        description.value,
        dimensionId.value,
        utcDueDate
      );
      if (addToTemplates.value) {
        await profApi.createActionTemplate(
          nameObj.value.actionName,
          description.value,
          dimensionId.value
        );
      }
      loader.hide();
      isDirty.value = false;
      router.replace({
        name: 'patient-details',
        params: { pid: patientId.value },
        query: { tab: 'goals-details', expanded: goalId.value },
      });
    };

    // add a template action to goal
    const selectedActionId = ref(-1);
    const templateDimensionId = ref(-1);
    const templateDueDate = ref('');
    const useTemplateAction = async () => {
      if (selectedActionId.value == -1 || templateDimensionId.value == -1)
        return;
      const loader = loading.show({
        container: addActionContainer.value,
        isFullPage: false,
      });
      const utcDueDate = !templateDueDate.value
        ? null
        : dayjs.utc(`${templateDueDate.value} 11:59 PM`, 'YYYY-MM-DD HH:mm A');
      await profApi.addActionFromTemplate(
        patientId.value,
        goalId.value,
        selectedActionId.value,
        templateDimensionId.value,
        utcDueDate
      );
      loader.hide();
      isDirty.value = false;
      router.replace({
        name: 'patient-details',
        params: { pid: patientId.value },
        query: { tab: 'goals-details', expanded: goalId.value },
      });
    };

    // prefill the template dimension dropdown with the action's default dimension
    watch(selectedActionId, () => {
      if (selectedActionId.value == -1) {
        templateDimensionId.value = -1;
        return;
      }
      const selectedAction = templateActions.value.find(
        (action) => action.id == selectedActionId.value
      );
      templateDimensionId.value = selectedAction.defaultDimensionId || -1;
    });

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      nameObj,
      description,
      dimensionId,
      dueDate,
      addToTemplates,
      addNewAction,
      'add-action-container': addActionContainer,
      dimensions,
      templateActions,
      selectedActionId,
      templateDimensionId,
      templateDueDate,
      useTemplateAction,
      patientId,
      goalId,
      isDirty,
    };
  },
};
</script>
