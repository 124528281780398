<template>
  <div
    ref="new-message-container"
    class="container-lg p-4 position-relative collapse show"
  >
    <div class="row">
      <!-- Create new one off action with option to save to library -->
      <div class="col-md-4">
        <label>To</label>
        <div id="contact-selection" class="list-group">
          <button
            v-for="contact in contacts"
            :key="contact.id"
            :class="{
              'list-group-item': true,
              'list-group-item-action': true,
              active: contact.isSelected,
            }"
            @click="contactClick(contact)"
          >
            <div class="d-flex align-items-center">
              <div
                class="circle-profile shadow me-3"
                :style="`background-image: url(${profilePicSrc(contact.id)});`"
              ></div>
              <p>
                {{ contact.name }}
              </p>
            </div>
          </button>
        </div>
      </div>
      <div class="col-md-8 mt-4">
        <div class="input-group mb-3 w-100">
        <div class="form-floating w-75">
          <input
            id="actionName"
            v-model="subject"
            type="text"
            class="form-control"
            placeholder="Action Name"
            @keyup.enter="sendMessage"
            @input="$emit('dirty')"
          />
          <label for="actionName">Subject</label>
        </div>
        <button
            id="send-message-btn"
            :disabled="recipientIds.length === 0 || !subject || !body.data"
            type="button"
            class="btn btn-primary w-25"
            @click="sendMessage"
          >
            Send Message
          </button>
        </div>
        <div id="body-rte-container">
          <rich-text-editor
            id="new-message-body"
            v-model:data="body"
            :data-id="body.id"
            property="data"
            container-id="body-rte-container"
            height="50vh"
            @dirty="$emit('dirty')"
          ></rich-text-editor>
        </div>
      </div>
      <!-- <div class="row mt-3">
        <div class="col text-end">
          <button
            id="send-message-btn"
            :disabled="recipientIds.length === 0 || !subject || !body.data"
            type="button"
            class="btn btn-primary"
            @click="sendMessage"
          >
            Send Message
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, reactive } from 'vue';
import RichTextEditor from '../shared/components/RichTextEditor.vue';

export default {
  components: {
    RichTextEditor,
  },
  props: {
    rid: {
      type: String,
      default: '',
    },
    mid: {
      type: String,
      default: '',
    },
  },
  emits: ['msg-sent', 'dirty', 'clean'],
  setup(props, context) {
    const messageApi = inject('api').messageApi();
    const loading = inject('$loading');
    const contacts = ref([]);
    const newMessageContainer = ref(null);
    const recipientIds = ref([]);

    onMounted(async () => {
      const loader = loading.show({
        container: newMessageContainer.value,
        isFullPage: false,
      });
      const response = await messageApi.getContacts();
      if (props.rid) {
        response.data.forEach((e) => {
          if (e.id == props.rid) {
            e.isSelected = true;
            recipientIds.value.push(e.id);
          }
        });
      } else if (response.data.length == 1) {
        response.data[0].isSelected = true;
        recipientIds.value.push(response.data[0].id);
      }
      if (props.mid) {
        const messageResponse = await messageApi.getMessageDetails(props.mid);
        const messageDetails = messageResponse.data;
        body.id = messageDetails.id;
        body.data =
          '<br><br><br><p>-------------</p><br><p><span style="color: rgb(136, 136, 136);">' +
          messageDetails.body +
          '</span></p>';
        subject.value = 'RE: ' + messageDetails.subject;
      }
      contacts.value = response.data;
      loader.hide();
    });

    const subject = ref('');
    const body = reactive({ id: -1, data: '' });
    const sendMessage = async () => {
      if (recipientIds.value.length === 0 || !subject.value || !body.data)
        return;
      const loader = loading.show({
        container: newMessageContainer.value,
        isFullPage: false,
      });
      await messageApi.sendMessage(
        recipientIds.value,
        subject.value,
        body.data
      );
      loader.hide();
      context.emit('clean');
      context.emit('msg-sent');
    };

    const profilePicSrc = (pid) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${pid}`;
    };

    const contactClick = (contact) => {
      contact.isSelected = !contact.isSelected;
      const selected = [];
      for (let i = 0; i < contacts.value.length; i++) {
        if (contacts.value[i].isSelected) selected.push(contacts.value[i].id);
      }
      recipientIds.value = selected;
    };

    return {
      'new-message-container': newMessageContainer,
      contacts,
      recipientIds,
      subject,
      body,
      sendMessage,
      profilePicSrc,
      contactClick,
    };
  },
};
</script>
<style scoped>
img.profile-pic {
  height: 3em;
}
</style>
