<template>
  <div id="collapse-G" class="collapse show">
    <div ref="dimensions-details-container" class="card-body position-relative">
      <div class="row">
        <div class="col small">
          Progress Total
          <div class="progress mb-3" style="height: 30px">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              :style="`width: ${progressData.progressTotalPct}%`"
            >
              {{ Math.trunc(progressData.progressTotalPct) }}%
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-2 row-cols-sm-4 g-2 text-left small">
        <div class="col">
          Emotional
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.emotionalPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Environmental
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.environmentalPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Financial
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.financialPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Intellectual
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.intellectualPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Occupational
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.occupationalPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Physical
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.physicalPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Social
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.socialPct}%`"
            ></div>
          </div>
        </div>
        <div class="col">
          Spiritual
          <div class="progress">
            <div
              class="progress-bar bg-sky"
              role="progressbar"
              :style="`width: ${progressData.spiritualPct}%`"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';

export default {
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const profApi = inject('api').profApi();
    const loading = inject('$loading');

    const dimensionsDetailsContainer = ref(null);
    const progressData = ref({});
    onMounted(() => {
      refresh();
    });

    const refresh = async () => {
      const loader = loading.show({
        container: dimensionsDetailsContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getDimensionProgress(props.patientId);
      progressData.value = response.data;
      loader.hide();
    };

    return {
      'dimensions-details-container': dimensionsDetailsContainer,
      progressData,
      refresh,
    };
  },
};
</script>
