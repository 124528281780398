<template>
  <!-- start tab section -->
  <div ref="messages-view" class="col responsive-tabs">
    <!-- tabbed nav for desktop hidden on mobile view -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'inbox-messages' }"
          style="cursor: pointer"
          @click="setTab('inbox-messages')"
          >Inbox</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'new-message' }"
          style="cursor: pointer"
          @click="setTab('new-message')"
          >New Message</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'sent-messages' }"
          style="cursor: pointer"
          @click="setTab('sent-messages')"
          >Sent Messages</a
        >
      </li>
    </ul>
    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content mb-5">
      <!-- tab one -->
      <div
        id="inbox-messages"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'inbox-messages' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'inbox-messages' }"
              style="cursor: pointer"
              @click="setTab('inbox-messages')"
              >Inbox</a
            >
          </h5>
        </div>
        <inbox-messages
          v-if="currTab == 'inbox-messages'"
          :curr-tab="currTab"
          @reply="startReply"
        ></inbox-messages>
      </div>
      <!-- tab two -->
      <div
        id="sent-messages"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'sent-messages' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'sent-messages' }"
              style="cursor: pointer"
              @click="setTab('sent-messages')"
              >Sent Messages</a
            >
          </h5>
        </div>
        <sent-messages
          v-if="currTab == 'sent-messages'"
          :curr-tab="currTab"
        ></sent-messages>
      </div>
      <!-- tab three -->
      <div
        id="new-message"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'new-message' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'new-message' }"
              style="cursor: pointer"
              @click="setTab('new-message')"
              >New Message</a
            >
          </h5>
        </div>
        <new-message
          v-if="currTab == 'new-message'"
          :rid="$route.query.rid"
          :mid="$route.query.mid"
          @msg-sent="setTab('sent-messages')"
          @clean="isDirty = false"
          @dirty="isDirty = true"
        ></new-message>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import InboxMessages from './InboxMessages.vue';
import SentMessages from './SentMessages.vue';
import NewMessage from './NewMessage.vue';

export default {
  components: {
    InboxMessages,
    SentMessages,
    NewMessage,
  },
  setup() {
    const currTab = ref('inbox-messages');

    const route = useRoute();
    const router = useRouter();

    const setTab = (newTab) => {
      if (isDirty.value) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      currTab.value = newTab;
      router.replace({ query: { currTab: newTab } });
    };

    const messagesView = ref(null);

    onMounted(() => {
      if (route.query.currTab) {
        currTab.value = route.query.currTab;
      }

      window.scrollTo(0, messagesView.value.offsetTop);
    });

    const startReply = (message) => {
      router.replace({ query: { mid: message.id, rid: message.sender.id } });
      currTab.value = 'new-message';
    };

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      currTab,
      startReply,
      setTab,
      isDirty,
      'messages-view': messagesView,
    };
  },
};
</script>
