<template>
  <div id="collapse-C" class="collapse show">
    <div class="card-body">
      <div class="row">
        <!-- dreams & actions -->
        <life-plan-component :patient-id="patientId" />

        <!-- bucket List -->
        <bucket-list-component :patient-id="patientId" />
      </div>
    </div>
  </div>
</template>

<script>
import LifePlanComponent from './LifePlanComponent.vue';
import BucketListComponent from './BucketListComponent.vue';

export default {
  components: {
    LifePlanComponent,
    BucketListComponent,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
};
</script>
