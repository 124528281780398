<template>
  <base-modal
    ref="patient-goal-modal"
    :show-btn-classes="{ btn: true, 'btn-sm': true }"
    @modal-opened="refreshModalData"
    @exit="$emit('clean')"
  >
    <template #show-btn-content>
      <i class="fas fa-edit"></i>
    </template>
    <template #header-title>Edit Goal</template>
    <template #body
      ><div class="row">
        <div class="col-md-6">
          <div class="row g-2">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input
                  :id="`goal-${goal.id}-name`"
                  v-model="localGoal.title"
                  type="text"
                  class="form-control"
                  placeholder="Goal Name"
                  @keyup.enter="editGoal"
                />
                <label :for="`goal-${goal.id}-name`">Goal Name</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea
                  :id="`goal-${goal.id}-description`"
                  v-model="localGoal.description"
                  class="form-control"
                  placeholder="Optional Description"
                ></textarea>
                <label :for="`goal-${goal.id}-description`"
                  >Description (optional)</label
                >
              </div>
            </div>
          </div>
        </div>
      </div></template
    >
    <template #footer>
      <button
        :id="`close-goal-${goal.id}-modal`"
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        :id="`save-goal-${goal.id}-modal`"
        :disabled="!localGoal.title"
        type="button"
        class="btn btn-success"
        @click="editGoal"
      >
        Save &amp; Close
      </button>
    </template>
  </base-modal>
</template>

<script>
import { ref, inject } from 'vue';
import BaseModal from '../../../../../shared/UI/BaseModal.vue';
export default {
  components: {
    BaseModal,
  },
  props: {
    dimensions: {
      type: Array,
      required: true,
    },
    goal: {
      type: Object,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['updated', 'clean', 'dirty'],
  setup(props, context) {
    // create copy of goal, to be edited
    const localGoal = ref({});
    const refreshModalData = () => {
      localGoal.value = JSON.parse(JSON.stringify(props.goal));
      context.emit('dirty');
    };

    // save changes
    const profApi = inject('api').profApi();
    const patientGoalModal = ref(null);
    const editGoal = async () => {
      await profApi.editPatientGoal(props.patientId, localGoal.value);
      context.emit('updated');
      context.emit('clean');
      patientGoalModal.value.toggleModal();
    };

    const toggleModal = () => {
      patientGoalModal.value.toggleModal();
    };

    const cancel = () => {
      context.emit('clean');
      toggleModal();
    };

    return {
      localGoal,
      'patient-goal-modal': patientGoalModal,
      editGoal,
      toggleModal,
      refreshModalData,
      cancel,
    };
  },
};
</script>
