<template>
  <div class="small text-center d-flex justify-content-start pb-4">
    <router-link
      id="back-btn"
      :to="{
        name: 'patient-details',
        params: { pid: patientId },
        query: { tab: 'goals-details' },
      }"
      class="btn btn-sm btn-link link-secondary"
    >
      <i class="fas fa-angle-double-left"></i>
      Back to Patient Goals
    </router-link>
  </div>
  <div ref="add-goal-container" class="row position-relative">
    <!-- create new goal -->
    <div class="col-md-6">
      <h2>Create New Goal</h2>
      <div class="row g-2">
        <div class="col-12">
          <div class="form-floating mb-3">
            <input
              id="goalName"
              v-model="goalName"
              type="text"
              class="form-control"
              placeholder="Action Name"
              @keyup.enter="createGoal"
              @input="isDirty = true"
            />
            <label for="goalName">Goal Name</label>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating mb-3">
            <textarea
              id="goalDescription"
              v-model="description"
              class="form-control"
              placeholder="Leave a comment here"
              @input="isDirty = true"
            ></textarea>
            <label for="goalDescription">Description (optional)</label>
          </div>
        </div>
      </div>
      <div class="col text-end">
        <button
          id="create-goal-btn"
          :disabled="!goalName"
          type="button"
          class="btn btn-primary"
          @click="createGoal"
        >
          Create Goal
        </button>
      </div>
    </div>
    <!-- use save goal set -->
    <!-- <div class="col">
      <h2>Import Goal Set</h2>
      <div class="row g-2">
        <div class="col">
          <div class="form-floating mb-3">
            <select
              id="selectGoalSet"
              v-model="selectedGoalId"
              class="form-select"
              @input="isDirty = true"
            >
              <option :value="-1"></option>
              <option
                v-for="goal in templateGoals"
                :key="goal.id"
                :value="goal.id"
              >
                {{ goal.title }}
              </option>
            </select>
            <label for="selectGoalSet">Select Goal Set</label>
          </div>
        </div>
      </div>

      <div class="col text-end">
        <button
          id="use-template-btn"
          :disabled="selectedGoalId == -1"
          type="button"
          class="btn btn-primary"
          @click="useTemplateGoal"
        >
          Add Goal Set
        </button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ref, inject, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const loading = inject('$loading');
    const profApi = inject('api').profApi();
    const route = useRoute();
    const patientId = ref(route.params.pid);

    // get existing goal sets
    const addGoalContainer = ref(null);
    const templateGoals = ref([]);
    onMounted(async () => {
      const loader = loading.show({
        container: addGoalContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getTemplateGoals();
      templateGoals.value = response.data;
      loader.hide();
    });

    const router = useRouter();
    const goalName = ref('');
    const description = ref('');
    const createGoal = async () => {
      const loader = loading.show({
        container: addGoalContainer.value,
        isFullPage: false,
      });
      if (!goalName.value) {
        return;
      }
      await profApi.addCustomPatientGoal(
        patientId.value,
        goalName.value,
        description.value
      );
      loader.hide();
      isDirty.value = false;
      router.replace({
        name: 'patient-details',
        params: { pid: patientId.value },
        query: { tab: 'goals-details' },
      });
    };

    const selectedGoalId = ref(-1);
    const useTemplateGoal = async () => {
      if (selectedGoalId.value == -1) {
        return;
      }
      const loader = loading.show({
        container: addGoalContainer.value,
        isFullPage: false,
      });
      await profApi.addGoalFromTemplate(patientId.value, selectedGoalId.value);
      loader.hide();
      isDirty.value = false;
      router.replace({
        name: 'patient-details',
        params: { pid: patientId.value },
        query: { tab: 'goals-details' },
      });
    };

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      goalName,
      description,
      createGoal,
      'add-goal-container': addGoalContainer,
      templateGoals,
      selectedGoalId,
      useTemplateGoal,
      patientId,
      isDirty,
    };
  },
};
</script>
