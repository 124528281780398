<template>
    <div id="embed-container">
        <iframe style="background-color: white;" :src="url" width="100%" height="1080"></iframe>
    </div>
</template>

<script>
import {useRoute } from 'vue-router'

export default {
    name: "SocialMedia",
    setup() {
        const route = useRoute()
        return {
            url: route.query.url,
            route
        }
    }
};
</script>

<style scoped>
iframe {
    border: none;
}
</style>
