<template>
  <div ref="assigned-actions-container" class="col position-relative">
    <h3>Assigned Actions</h3>
    <div class="card mb-4">
      <!-- Start Header -->
      <div class="card-header pt-3">
        <div class="btn-toolbar">
          <!-- Search box -->
          <div
            class="input-group input-group-sm flex-grow-1 mb-2 me-lg-2 me-xl-0 me-xxl-2"
          >
            <input
              id="actions-filter"
              v-model.trim="nameFilter"
              type="text"
              class="form-control"
              placeholder="Search Actions"
              @keyup.enter="searchActions"
            />
            <button
              id="search-actions-btn"
              class="btn btn-outline-secondary"
              type="button"
              @click="searchActions"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
          <!-- Sort buttons -->
          <div
            class="btn-group btn-group-sm flex-grow-1 mb-2 ms-2"
            role="group"
          >
            <!-- <button
              type="abc-sort"
              class="btn btn-outline-secondary"
              :class="{ active: sort == 'TitleAsc' }"
              @click="abcSort"
            >
              <i class="fas fa-sort-alpha-down"></i>
            </button>
            <button
              type="zyx-sort"
              class="btn btn-outline-secondary"
              :class="{ active: sort == 'TitleDesc' }"
              @click="zyxSort"
            >
              <i class="fas fa-sort-alpha-down-alt"></i>
            </button> -->
          </div>
          <!-- Filter buttons -->
          <div
            class="btn-group btn-group-sm flex-grow-1 mb-2 ms-2"
            role="group"
          >
            <button
              id="complete-asc"
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: completedFilter == 'Incomplete' }"
              @click="changeIncompleteFilter"
            >
              <i class="far fa-square"></i>
            </button>
            <button
              id="complete-desc"
              type="button"
              class="btn btn-outline-secondary"
              :class="{ active: completedFilter == 'Complete' }"
              @click="changeCompletedFilter"
            >
              <i class="far fa-check-square"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- card body -->
      <ul
        v-if="actions.length > 0"
        class="list-group lh-sm list-group-flush mb-4"
      >
        <!-- Action - Completed  -->
        <li
          v-for="action in actions"
          :key="action.id"
          class="list-group-item list-group-item-action"
          :class="getClasses(action)"
          @click="$emit('expand', action.goalId)"
        >
          <div class="d-flex align-items-center">
            <i v-if="action.isComplete" class="fas fa-check-square me-2"></i>
            <div class="div">
              <!-- eslint-disable vue/no-v-html -->
              <span class="actionTitle" v-html="action.title"></span>
              <!-- eslint-enable -->
            </div>
          </div>
          <div class="small d-flex justify-content-md-between mt-2 mt-md-0">
            <div>
              <div v-if="action.dueDateUtc && !action.isComplete">
                <i :class="`fas ${getIcon(action)}`"></i>
                {{ convertToLocal(action.dueDateUtc) }}
              </div>
            </div>
            <span class="badge rounded-pill bg-dark ms-auto">{{
              action.dimensionName
            }}</span>
          </div>
        </li>
      </ul>
      <div v-else class="text-center py-4">
        <span class="text-muted"> No assigned actions </span>
      </div>

      <!-- Pagination -->
      <tbl-page-seln
        v-model="pgNum"
        :total-pages="totalPages"
        @updated="refresh"
      />
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';
import TblPageSeln from '../../../../shared/components/TblPageSeln.vue';
import stdLocalTime from '../../../../../../services/lib/localTime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default {
  components: {
    TblPageSeln,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['expand'],
  setup(props) {
    const loading = inject('$loading');
    const profApi = inject('api').profApi();
    dayjs.extend(utc);

    const actions = ref([]);

    onMounted(() => {
      refresh();
    });

    const assignedActionsContainer = ref(null);
    const pgSz = ref(6);
    const pgNum = ref(1);
    const sort = ref('TitleAsc');
    const nameFilter = ref('');
    const completedFilter = ref('None');

    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: assignedActionsContainer.value,
        isFullPage: false,
      });
      const response = await profApi.getActions(
        props.patientId,
        pgSz.value,
        pgNum.value - 1,
        sort.value,
        nameFilter.value,
        completedFilter.value
      );
      actions.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
    };

    const convertToLocal = (utcTime) => {
      return stdLocalTime(utcTime, true);
    };

    const getDueDateStatus = (action) => {
      if (action.isComplete || !action.dueDateUtc) {
        return '';
      }
      const dt = dayjs(action.dueDateUtc).local().startOf('day');
      if (dayjs().isSame(dt, 'day')) return 'warning'; // due today
      if (dayjs().isAfter(dt, 'day')) return 'late'; // overdue
      return '';
    };

    const getIcon = (action) => {
      const status = getDueDateStatus(action);
      if (status == 'warning') return 'fa-exclamation-triangle';
      if (status == 'late') return 'fa-exclamation-circle';
      return 'fa-calendar';
    };

    const getClasses = (action) => {
      if (action.isComplete) return 'list-group-item-success';
      const status = getDueDateStatus(action);
      if (status == 'warning') return 'list-group-item-warning';
      if (status == 'late') return 'list-group-item-danger';
      return '';
    };

    const abcSort = () => {
      if (sort.value == 'TitleAsc') return;
      sort.value = 'TitleAsc';
      refresh();
    };

    const zyxSort = () => {
      if (sort.value == 'TitleDesc') return;
      sort.value = 'TitleDesc';
      refresh();
    };

    const changeIncompleteFilter = () => {
      if (completedFilter.value == 'Incomplete') {
        completedFilter.value = 'None';
      } else {
        completedFilter.value = 'Incomplete';
      }
      refresh();
    };

    const changeCompletedFilter = () => {
      if (completedFilter.value == 'Complete') {
        completedFilter.value = 'None';
      } else {
        completedFilter.value = 'Complete';
      }
      refresh();
    };

    const searchActions = () => {
      pgNum.value = 1;
      refresh();
    };

    return {
      refresh,
      actions,
      'assigned-actions-container': assignedActionsContainer,
      pgNum,
      sort,
      nameFilter,
      totalPages,
      convertToLocal,
      getDueDateStatus,
      getIcon,
      getClasses,
      abcSort,
      zyxSort,
      changeIncompleteFilter,
      changeCompletedFilter,
      completedFilter,
      searchActions,
    };
  },
};
</script>
