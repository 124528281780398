<template>
  <div
    ref="goals-container"
    class="row row-cols-1 row-cols-sm-2 row-cols-md-1 position-relative"
  >
    <h5 id="goals" class="mt-3 w-100">My Treatment Goals</h5>
    <div v-if="goals.length == 0" class="col mb-2">
      <span class="text-muted">
        Your health professional has not yet created any goals for you.
      </span>
    </div>
    <div v-for="goal in goals" :key="goal.id" class="col mb-2">
      {{ goal.title }}
      <div class="progress">
        <div
          class="progress-bar text-end pe-3"
          :class="progressBarClasses(goal)"
          :style="`width: ${Math.trunc(goal.pctComplete)}%`"
        >
          {{ goal.pctComplete &lt; 100 ? `${Math.trunc(goal.pctComplete)}%` : 'Completed' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, inject } from 'vue';

export default {
  setup() {
    const patientApi = inject('api').patientApi();
    const loading = inject('$loading');

    const pgNum = ref(1);
    const pgSz = ref(5);
    const sort = ref('CompleteDesc');
    const filter = ref('');
    const disableNext = ref(null);

    onMounted(() => {
      refresh();
    });

    const goalsContainer = ref(null);
    const goals = ref([]);

    const refresh = async () => {
      const loader = loading.show({
        container: goalsContainer.value,
        isFullPage: false,
      });

      // refresh actions/todos here

      const goalsResponse = await patientApi.getGoals(
        pgSz.value,
        pgNum.value - 1,
        sort.value,
        filter.value
      );
      disableNext.value = goalsResponse.data.totalPages <= pgNum.value;
      goals.value = goalsResponse.data.data;

      // refresh recent contracts here

      loader.hide();
    };

    const progressBarClasses = (goal) => {
      return {
        'bg-primary': goal.pctComplete < 75,
        'bg-honey': goal.pctComplete >= 75 && goal.pctComplete < 100,
        'bg-gold': goal.pctComplete == 100,
      };
    };

    return {
      pgNum,
      sort,
      filter,
      disableNext,
      'goals-container': goalsContainer,
      refresh,
      goals,
      progressBarClasses,
    };
  },
};
</script>
