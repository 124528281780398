<template>
  <div id="collapse-B" class="collapse show">
    <div class="card-body">
      <div class="row">
        <!-- patient goals -->
        <patient-goals
          ref="patient-goals-comp"
          :patient-id="patientId"
          @clean="$emit('clean')"
          @dirty="$emit('dirty')"
          @refresh="refreshActions"
        />

        <!-- patient activities -->
        <patient-actions
          ref="patient-actions-comp"
          :patient-id="patientId"
          @expand="expandGoal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import PatientGoals from './patient-goals/PatientGoals.vue';
import PatientActions from './PatientActions.vue';

export default {
  components: {
    PatientGoals,
    PatientActions,
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  emits: ['dirty', 'clean'],
  setup() {
    const patientGoalsComp = ref(null);
    const expandGoal = (goalId) => {
      patientGoalsComp.value.expandGoal(goalId);
    };

    const patientActionsComp = ref(null);
    const refreshActions = () => {
      patientActionsComp.value.refresh();
    };

    return {
      'patient-goals-comp': patientGoalsComp,
      expandGoal,
      'patient-actions-comp': patientActionsComp,
      refreshActions,
    };
  },
};
</script>
